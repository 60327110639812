<template>
    <div :class="['navframe', {
        'navframe-actived': actived,
        'navframe-hidden': hidden,
        'navframe-on': on
    }]">
        <bxs-layout class="navframe--cnt">
            <div>
                <logo ref="logo" />
            </div>

            <div>
                <button
                type="button"
                style="font-size: 21px;"
                @click.prevent="$emit('click:menu')">{{ on ? 'Close' : 'Menu' }}</button>
            </div>
        </bxs-layout>
    </div>
</template>

<script>
export default {
    name: 'navframe',
    props: {
        actived: {
            type: Boolean,
            required: false,
            default: false
        },
        hidden: {
            type: Boolean,
            required: false,
            default: false
        },
        on: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.navframe {
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: var(--color-background);
    transition: transform 0.25s ease-out;
    overflow: hidden;
    z-index: var(--layer-navframe);

    &.navframe-on {
        background: none;
    }

    &.navframe-actived {
        > .navframe--cnt {
            // border-bottom-color: var(--color-typo);
            &::after {
                background-color: var(--color-typo);
            }
        }
    }

    &.navframe-hidden {
        transform: translateY(-100%);
    }

    &--cnt {
        position: relative;
        min-height: var(--navbar-min-height);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: calc(100% - var(--grid-gutter) * 2);
            height: var(--thickness);
            transition: all 0.25s ease-out;
        }
    }
}
</style>