<template>
    <div class="contacts-view">
        <hero :title="page.title" />

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section v-if="page && page.content">
            <bxs-layout>
                <div
                v-html="page && page.content"
                class="wysiwyg"></div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section class="pt-0">
           <bxs-layout>
                <bxs-row justify="between">
                    <bxs-col
                    cols="12"
                    ds="5"
                    dl="4"
                    class="mb-ui">
                        <buro-links
                        hide-to
                        class="mb-ui" />

                        <div class="responsive-c">
                            <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2778.5037341572165!2d8.9452729161123!3d45.861230579107534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478429fcd7638205%3A0x3cfffe2e1330ac91!2sVia%20Mastri%20Ligornettesi%2024%2C%206853%20Ligornetto%2C%20Svizzera!5e0!3m2!1sit!2sit!4v1651324746606!5m2!1sit!2sit"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            class="responsive-iframe"></iframe>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="6">
                        <c-form
                        ref="form"
                        @submit="submit">
                            <div class="mb-6">
                                <h5 class="mb-1">Contact us for more information</h5>
                                <p class="mb-0 text-mute">We will get back to you as soon as possible.</p>
                            </div>

                            <div :key="key">
                                <div>
                                    <text-field
                                    ref="fullnameField"
                                    v-model="form.fullname"
                                    label="Fullname"
                                    required
                                    name="name"
                                    :rules="[v => !!v || 'Field required']"  />
                                </div>

                                <div>
                                    <text-field
                                    ref="emailField"
                                    v-model="form.email"
                                    label="Email"
                                    name="email"
                                    required
                                    type="email"
                                    :rules="[v => /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(v) || 'Invalid email address']" />
                                </div>

                                <div>
                                    <text-field
                                    ref="subjectField"
                                    v-model="form.subject"
                                    label="Subject"
                                    placeholder="Project, work, info, other"
                                    name="subject" />
                                </div>

                                <div>
                                    <textarea
                                    v-model="form.message"
                                    id="message"
                                    name="message"
                                    class="textarea-field"
                                    placeholder="Write your message here"
                                    rows="4"
                                    cols="50" />
                                </div>
                            </div>

                            <div>
                                <div class="mt-2">
                                    <div class="flex nowrap align-center mb-3">
                                        <input
                                        v-model="is_checked_pp"
                                        type="checkbox"
                                        id="vehicle1"
                                        name="vehicle1"
                                        :value="true">
                                        <label for="vehicle1" class="ml-2 text-mute">
                                            <small class="ma-0 pa-0">I agree to the <router-link to="/privacy-policy">privacy policy</router-link></small>
                                        </label>
                                    </div>

                                    <c-btn
                                    :disabled="!is_checked_pp || loading"
                                    :loading="loading"
                                    block
                                    @click="$refs.form.submit()">
                                        <span v-if="!loading">Send</span>
                                        <span v-else>Loading ...</span>
                                    </c-btn>
                                </div>

                                <!-- error message -->
                                <div
                                v-if="error"
                                class="flex nowrap align-start mt-4 text-error">
                                    <c-arrow
                                    size="12px"
                                    class="mr-4" />

                                    <div>
                                        <small class="mb-0">Oops, something went wrong.</small> <br>
                                        <small>{{ error.message }}</small>
                                    </div>
                                </div>

                                <!-- response message -->
                                <div
                                v-if="res && res.status === 'mail_sent'"
                                class="mt-4 text-center">
                                    <p class="mb-0">{{ res.message }}</p>
                                </div>

                                <!-- policy message -->
                                <div class="mt-ui">
                                    <small
                                    style="font-size: 12px; line-height: normal !important;"
                                    class="text-mute mb-0"><i>Click “Send” to agree to Suisse Consulences’s terms of service and acknowledge that Suisse Consulences’s <router-link to="/privacy-policy">privacy policy</router-link> applies to you.</i></small>
                                </div>
                            </div>

                            <hr>
                        </c-form>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import Form from '@/components/Form/Form.vue'
import TextField from '@/components/Form/TextField.vue'

export default {
    name: 'contacts',
    components: {
        'c-form': Form,
        'text-field': TextField
    },
    data () {
        return {
            key: 1,
            loading: false,
            error: null,
            res: null,
            is_checked_pp: false,
            form: {
                fullname: null,
                email: null,
                subject: null,
                message: null
            }
        }
    },
    computed: {
        ...mapState({
            page: state => state.page
        })
    },
    methods: {
        async submit () {
            this.loading = true
            this.error = null
            const form = new FormData()
            form.append('your-name', this.form.fullname)
            form.append('your-email', this.form.email)
            form.append('your-subject', this.form.subject)
            form.append('your-message', this.form.message)

            try {
                this.res = await this.$wp.submitForm('316', form)

                if (this.res.status !== 'mail_sent') {
                    this.error = this.res
                    return
                }

                this.form = {
                    fullname: null,
                    email: null,
                    subject: null,
                    message: null
                }
                this.key += 1
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
                this.$root.resize()
            }
        }
    }
}
</script>