<template>
    <div class="work-view">
        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section class="pt-0">
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="8"
                    order-ds="2"
                    class="flex column justify-between">
                        <div class="text-right mb-ui h2">
                            <c-arrow dir="left" />
                        </div>

                        <div
                        class="text-center"
                        style="max-width: 768px; margin: 0 auto;">
                            <h1 class="h2 mb-1">{{ item.title }}</h1>
                            <p class="h5">{{ item.place }}</p>
                        </div>

                        <div class="sc-toolbar justify-between">
                            <small class="mb-0">{{ item.created_at }}</small>
                            <small class="mb-0">{{ item.customer_name }}</small>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="12"
                    ds="4"
                    order-ds="1">
                        <bxs-figure
                        :key="item.img_url"
                        :src="item.img_url"
                        ratio="2/3" />
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout max-width="1024px">
                <div
                v-if="item.categories && item.categories.length > 0"
                class="mb-ui">
                    <ul>
                        <li
                        v-for="(cat, i) in item.categories.map(v => v.name).sort()"
                        :key="i"
                        class="sc-list-item">
                            <div class="flex nowrap align-center">
                                <small class="mr-4 mb-0"><span v-if="item.categories.length < 11">0</span>{{ i + 1 }}</small>
                                <h6 class="mb-0">{{ cat }}</h6>
                            </div>
                        </li>
                    </ul>
                </div>

                <div
                v-if="item.content"
                v-html="item.content"
                class="sc-wysiwyg mt-16"></div>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section v-if="!$store.state.is_mobile">
            <carousel
            :items="item.imgs"
            centered
            no-hoverable>
                <template #item="{ item }">
                    <bxs-figure
                    :src="item"
                    ratio="4/3" />
                </template>
            </carousel>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section v-if="$store.state.is_mobile">
            <bxs-layout max-width="1280px">
                <bxs-row>
                    <bxs-col
                    v-for="(src, i) in item.imgs"
                    :key="i"
                    cols="12"
                    ds="12">
                        <bxs-figure :src="src" />
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <contacts-section  />

        <!-- ------------------------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout class="mb-ui">
                <div>
                    <button
                    class="flex nowrap align-center"
                    @click.prevent="$router.push('/works')">
                        <c-arrow
                        dir="left"
                        class="mr-2"></c-arrow>

                        <router-link to="/works">More projects</router-link>
                    </button>
                </div>
            </bxs-layout>

            <carousel
            :items="works.docs"
            centered>
                <template #item="{ item }">
                    <project-item-slide :item="item" />
                </template>
            </carousel>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'work',
    computed: {
        ...mapState({
            item: state => state.work,
            works: state => state.works
        })
    }
}
</script>