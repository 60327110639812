<template>
    <div class="sc-home-carousel">
        <swiper
        :speed="1000"
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        :scrollbar="false"
        grab-cursor
        :pagination="{
            clickable: true,
            enabled: true
        }"
        :mousewheel="{
            forceToAxis: true,
            invert: false,
            releaseOnEdges: false,
            sensitivity: 0.8
        }">
            <swiper-slide
            v-for="(item, i) in items"
            :key="i"
            class="r r-home-slide"
            :style="{
                backgroundImage: `url(${item.img_url})`
            }">
                <div class="overlay"></div>

                <div class="content">
                    <div class="spacer"></div>

                    <!-- v-if="item.title" -->
                    <p
                    ref="title"
                    data-anim
                    class="h4 mb-0">{{ item.title || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, fugiat iste consequatur veritatis reprehenderit provident id, nobis itaque culpa, qui at? Optio quis expedita deleniti ipsum debitis facilis consequuntur possimus?' }}</p>

                    <!-- v-if="item.title && item.paragraph" -->
                    <div
                    data-anim
                    ref="hr"
                    class="hr my-2"></div>

                    <!-- v-if="item.paragraph" -->
                    <p
                    ref="paragraph"
                    data-anim
                    class="mb-0">
                        <small>{{ item.paragraph || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, fugiat iste consequatur veritatis reprehenderit provident id, nobis itaque culpa, qui at? Optio quis expedita deleniti ipsum debitis facilis consequuntur possimus?' }}</small>
                    </p>

                    <!-- v-if="item.to" -->
                    <router-link
                    ref="to"
                    data-anim
                    :to="$filters.toto(item.to)"
                    exact
                    class="mt-ui">
                        <span>Read more</span>
                        <c-arrow class="ml-4" />
                    </router-link>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import {
    Pagination,
    Navigation,
    Scrollbar,
    Mousewheel,
    FreeMode
} from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

export default {
    name: 'sc_carousel',
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            modules: [
                Pagination,
                Navigation,
                Scrollbar,
                Mousewheel,
                FreeMode
            ]
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/mq.scss';
@import '@/assets/styles/mixins.scss';

.r-home-slide {
    @include aspect-ratio(2, 3);

    @include mq (ml) {
        @include aspect-ratio(16, 9);
    }

    @include mq (ds) {
        @include aspect-ratio(16, 9);
    }
}

.sc-home-carousel {
    background-color: rgba(#000, 0.1);

    .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
            position: relative;
            width: 100% !important;
            max-width: 100% !important;
            background-position: center;
            background-size: cover;

            > .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none !important;
                background-image: linear-gradient(
                    to right top,
                    rgba(#000, 0.5),
                    rgba(#000, 0)
                );
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                z-index: 0;
            }

            > .content {
                // width: 100%;
                // height: 100%;
                max-width: 568px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 2rem 1rem;
                color: white;
                z-index: 1;

                @include mq (ml) {
                    padding: 2rem 3rem;
                }

                .spacer {
                    flex: 1;
                }

                .hr {
                    display: inline-block;
                    width: 100%;
                    height: 1px;
                    background-color: white;
                }
            }
        }

        .swiper-pagination {
            display: block;
            top: 1rem;
            // left: 1rem;
            width: calc(100% - 1rem);
            text-align: right;
            pointer-events: none;

            @include mq (ds) {
                // left: 3rem;
                top: unset;
                bottom: 2rem;
                width: calc(100% - 3rem);
            }

            .swiper-pagination-bullet {
                opacity: 1;
                width: 40px;
                height: 2px;
                border-radius: 0;
                background-color: var(--color-background);
                transition: border 0.25s ease-out, background-color 0.25s ease-out;
                pointer-events: auto;

                &.swiper-pagination-bullet-active,
                &:hover {
                    opacity: 1;
                    background-color: var(--color-primary);
                    border: none;
                }
            }
        }
    }
}
</style>