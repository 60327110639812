<template>
    <router-link
    to="/"
    exact
    :style="{ maxWidth: maxWidth }"
    @click="$emit('click')">
        <svg
        ref="svg"
        xmlns="http://www.w3.org/2000/svg">
            <rect fill="#AC0401" height="300" width="300" />
            <rect fill="#FFF" height="60" width="200" x="50" y="120"/>
            <rect fill="#FFF" height="200" width="60" x="120" y="50"/>
        </svg>

        <span style="margin-right: 4px;">Suisse</span>
        <span>Consulences</span>
    </router-link>
</template>

<script>
import { fixSvgSpace } from '@/assets/libs/utils'

export default {
    name: 'logo',
    props: {
        'max-width': {
            type: String,
            required: false,
            default: '100%'
        }
    },
    mounted () {
        this.$nextTick(() => {
            fixSvgSpace(this.$refs.svg)
        })
    }
}
</script>

<style lang="scss" scoped>
$w: 21px;

a {
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    flex-flow: row nowrap;
    font-size: $w;
    text-decoration: none;
    font-weight: 400 !important;

    > svg {
        width: calc($w / 1.15);
        height: calc($w / 1.15);
        margin-right: 6px;
    }

    > span {
        margin-bottom: -1px;
        text-decoration: none;
    }
}
</style>