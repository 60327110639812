<template>
    <div class="big-menu">
        <bxs-layout class="big-menu--content">
            <div>
                <ul>
                    <li
                    v-for="(item, i) in items"
                    :key="i">
                        <router-link
                        :to="'/' + item.page.slug"
                        exact
                        class="h3"
                        data-big-menu-item>
                            <span>{{ item.title }}</span>
                            <c-arrow />
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="big-menu--content--footer">
                <bxs-row
                no-gutters
                align="center">
                    <bxs-col cols="6" ds="4">
                        <div data-big-menu-item>
                            <small>Suisse Consulences © {{ new Date().getFullYear() }}</small>
                        </div>
                    </bxs-col>

                    <bxs-col
                    cols="6" ds="4"
                    class="col-hide-ds">
                        <div
                        data-big-menu-item
                        class="text-center">
                            <small>Project et Développement de l'Ingénierie</small>
                        </div>
                    </bxs-col>

                    <bxs-col cols="6" ds="4">
                        <div
                        data-big-menu-item
                        class="text-right small">
                            <router-link to="/privacy-policy">Privacy policy</router-link>
                            <small class="mx-1">-</small>
                            <router-link to="/cookie-policy">Cookie Policy</router-link>
                        </div>
                    </bxs-col>
                </bxs-row>
            </div>
        </bxs-layout>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'bigMenu',
    computed: {
        ...mapState({
            items: state => state.menu
        })
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.big-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: var(--navbar-min-height);
    background-color: var(--color-primary);
    z-index: var(--layer-drawer);

    &--content {
        position: relative;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        padding-bottom: var(--grid-gutter);

        > div {
            overflow: hidden;

            > ul {
                > li {
                    cursor: pointer;
                    overflow: hidden;
                    border-bottom: var(--thickness) solid var(--color-typo);
                    height: var(--big-menu-list-item-height);

                    // @include mq (ml) {
                    //     height: calc(var(--navbar-min-height) * 1.5);
                    // }

                    // @include mq (dl) {
                    //     height: calc(var(--navbar-min-height) * 1.75);
                    // }

                    &:last-child {
                        border-bottom: none;
                    }

                    > a {
                        position: relative;
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        max-height: 100%;
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: space-between;

                        @media (hover: hover) {
                            &:hover {
                                color: white;
                            }
                        }

                        &.router-link-active {
                            color: white;
                        }
                    }
                }
            }
        }

        &--footer {
            height: var(--big-menu-footer-min-height);
            border-top: var(--thickness) solid var(--color-typo);
            border-bottom: var(--thickness) solid var(--color-typo);
            overflow: hidden;

            > .bxs-row {
                height: 100%;
            }
        }
    }
}
</style>