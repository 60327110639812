<template>
    <router-link
    :to="item.to"
    class="project-slide-item">
        <div>
            <bxs-figure
            :src="item.img_url || null"
            :alt="item.title || null"
            ratio="3/2">
                <!-- <figcaption>
                    <p>{{ item.title }}</p>
                    <small>{{ item.categories && item.categories.length > 0 ? item.categories[0].name : '' }}</small>
                </figcaption> -->
            </bxs-figure>

            <div>
                <p>{{ item.title }}</p>
                <p
                v-if="item.categories && item.categories.length > 0"
                class="text-mute">{{ item.categories[0].name }}</p>
            </div>
        </div>

        <div>
            <small>{{ item.customer_name}} <span v-if="item.customer_name && item.created_at"></span> {{ item.created_at }}</small>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'project_slide_item',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
p,
small,
a {
    margin-bottom: 0;
}

.project-slide-item {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    text-decoration: none;
    // border-bottom: 1px solid red;

    display: flex;
    flex-flow: row nowrap;

    > div {
        &:first-child {
            flex: 1;

            > div {
                padding-top: 0.5rem;

                &:last-child {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }

        &:last-child {
            padding-left: 0.5rem;

            > small {
                writing-mode: vertical-rl;
                text-orientation: mixed;

                > span {
                    display: inline-block;
                    width: 1px;
                    height: 20px;
                    background-color: #000;
                    margin: 0.5rem 0;
                }
            }
        }
    }
}
</style>
