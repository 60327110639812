<template>
    <section id="works">
        <bxs-layout>
            <div>
                <h2>{{ data_page ? data_page.title : 'Works' }}</h2>
            </div>

            <bxs-row justify="between">
                <bxs-col
                cols="12"
                ds="7">
                    <bxs-figure
                    v-if="data_page && data_page.highlighted_img"
                    :src="data_page.highlighted_img"
                    ratio="3/2" />

                    <bxs-figure
                    v-else
                    :src="null"
                    ratio="3/2" />
                </bxs-col>

                <bxs-col
                cols="12"
                ds="5">
                    <div
                    v-if="data_page"
                    v-html="data_page.content"
                    class="wysiwyg h5 mb-ui"
                    style="line-height: normal;"></div>

                    <c-btn
                    block
                    to="/works">See our works</c-btn>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'c-works',
    data () {
        return {
            data_page: null
        }
    },
    created () {
        this.$wp.getPage('works').then((doc) => {
            this.data_page = doc[0]
            this.$nextTick(() => this.$root.resize())
        })
    }
}
</script>